import React, { FC, useRef } from 'react';

import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useScroll, useTransform } from 'framer-motion';
import DemostrationOfSorting from 'guide/sorting/demonstrationOfSorting/DemonstrationOfSorting';
import {
    ChartArea,
    FolderCode,
    FolderTree,
    GraduationCap,
    Heart,
    LayoutList,
    Save,
    ShieldCheck,
    Star,
} from 'lucide-react';
import Image from 'next/image';

import CollapsePanel from '@components/Common/Collapse';
import GuideContent from '@components/Guide/Content';
import { IModule } from '@interfaces/guide';
import { useTranslation } from '@lib/i18n';
import { getLocalCompanyName } from '@lib/questions';

import { LandingHowWeTeachTextEn } from './LandingHowWeTeachTextEn';
import { LandingHowWeTeachTextRu } from './LandingHowWeTeachTextRu';
import {
    LandingContentWrapper,
    LandingIntro,
    LandingIntroDescription,
    LandingIntroPrint,
    LandingIntroTitle,
    NavigationCourse,
    NavigationItemAngle,
    NavigationItemDescription,
    NavigationItemInfo,
    NavigationItemTitle,
    LandingIntroBackgroundRadial,
    LandingIntroContent,
    LandingIntroBlinkingOnlineStatus,
    LandingIntroReviews,
    LandingIntroReviewsDescriptionRating,
    LandingIntroReviewsAvatarList,
    LandingIntroReviewsAvatarWrapper,
    LandingIntroReviewsDescription,
    LandingIntroReviewsDescriptionText,
    LandingIntroBackgroundBottomGradient,
    LandingIntroBackgroundTone,
    LandingIntroNavigation,
    NavigationTrainer,
    NavigationTelegram,
    LandingForWhom,
    LandingForWhomList,
    LandingForWhomListItem,
    LandingForWhomListItemDescription,
    LandingForWhomListItemHeader,
    LandingForWhomTitle,
    LandingForWhomContent,
    LandingForWhomListItemIcon,
    LandingHowWeTeach,
    LandingHowWeTeachContent,
    LandingHowWeTeachPlan,
    LandingDemo,
    LandingDemoContent,
    LandingSectionTitle,
    LandingTrainer,
    LandingTrainerContent,
    LandingTrainerTitle,
    LandingTrainerWrapper,
    LandingTrainerVideo,
    LandingTrainerVideoContent,
    LandingTrainerVideoContentTitle,
    LandingTrainerBenefits,
    LandingTrainerBenefit,
    LandingInterviewTasks,
    LandingInterviewTasksList,
    LandingInterviewTasksTitle,
    LandingInterviewTasksContent,
    LandingInterviewTaskCompany,
    LandingCertificate,
    LandingCertificateContent,
    LandingCertificateDescription,
    LandingCertificateTitle,
    LandingReview,
    LandingReviewTitle,
    LandingReviewColumns,
    LandingReviewItem,
    LandingReviewItemHeader,
    LandingReviewItemHeaderAvatar,
    LandingReviewItemHeaderName,
    LandingReviewItemText,
    LandingReviewRow,
    LandingFAQ,
    LandingFAQTitle,
    LandingFAQContent,
    LandingHowWeGuideContentWrapper,
    LandingForWhomWrapper,
    LandingCertificateImageBackdrop,
} from './StyledComponents';

interface IProps {
    modules: IModule[];
    landingStats: {
        amountUsers: number;
        amountSubmissions: number;
    };
}

const LandingContent: FC<IProps> = ({ modules, landingStats }) => {
    const { lang, t } = useTranslation('indexPage');

    const isRu = lang === 'ru';

    const LandingForWhomRef = useRef(null);
    const LandingHowWeTeachPlanRef = useRef(null);
    const behineCertificateRef = useRef(null);

    const { scrollYProgress: LandingForWhomYScroll } = useScroll({
        target: LandingForWhomRef,
        offset: ['0 1', '1.33 1'],
    });

    const { scrollYProgress: HowWeTeachPlanScrollYProgress } = useScroll({
        target: LandingHowWeTeachPlanRef,
        offset: ['0 1', '1.33 1'],
    });

    const { scrollYProgress: behineCertificateScrollYProgress } = useScroll({
        target: behineCertificateRef,
        offset: ['0 1', '1 1'],
    });

    const scaleProgressLandingForWhom = useTransform(
        LandingForWhomYScroll,
        [0, 1],
        [0.85, 1],
    );
    const opacityProgressLandingForWhom = useTransform(
        LandingForWhomYScroll,
        [0, 1],
        [0.9, 1],
    );
    const rotateXLandingForWhom = useTransform(
        LandingForWhomYScroll,
        [0, 1],
        ['-10deg', '0deg'],
    );
    const rotateYLandingForWhom = useTransform(
        LandingForWhomYScroll,
        [0, 1],
        ['-10deg', '0deg'],
    );

    const translateXTransformHowWeTeachPlan = useTransform(
        HowWeTeachPlanScrollYProgress,
        [0, 1],
        ['translateX(150px)', 'translateX(0px)'],
    );

    const rotateTransformHehineCertificate1 = useTransform(
        behineCertificateScrollYProgress,
        [0, 1],
        [
            'rotate(0deg) translateX(0px) translateY(0px)',
            'rotate(-7deg) translateX(-150px) translateY(60px)',
        ],
    );

    const rotateTransformHehineCertificate2 = useTransform(
        behineCertificateScrollYProgress,
        [0, 1],
        [
            'rotate(0deg) translateX(0px) translateY(0px)',
            'rotate(8deg) translateX(150px) translateY(60px)',
        ],
    );

    const amountThousandsUsers = Math.floor(landingStats.amountUsers / 1000);

    return (
        <LandingContentWrapper>
            <LandingIntro>
                <LandingIntroContent>
                    <LandingIntroTitle>
                        {t('intro.title1part')} <LandingIntroBlinkingOnlineStatus />{' '}
                        <br />
                        {t('intro.title2part')}
                    </LandingIntroTitle>

                    <LandingIntroDescription>
                        {t('intro.description')}
                    </LandingIntroDescription>

                    <LandingIntroReviews>
                        <LandingIntroReviewsAvatarList>
                            <LandingIntroReviewsAvatarWrapper>
                                <Image
                                    width={45}
                                    height={45}
                                    src="/static/reviewsAvatars/review5.webp"
                                    alt="SQL course review avatar"
                                />
                            </LandingIntroReviewsAvatarWrapper>
                            <LandingIntroReviewsAvatarWrapper>
                                <Image
                                    width={45}
                                    height={45}
                                    src="/static/reviewsAvatars/review4.webp"
                                    alt="SQL course review avatar"
                                />
                            </LandingIntroReviewsAvatarWrapper>
                            <LandingIntroReviewsAvatarWrapper>
                                <Image
                                    width={45}
                                    height={45}
                                    src="/static/reviewsAvatars/review3.webp"
                                    alt="SQL course review avatar"
                                />
                            </LandingIntroReviewsAvatarWrapper>
                            <LandingIntroReviewsAvatarWrapper
                                style={{ background: '#ff9800' }}
                            >
                                {amountThousandsUsers}k+
                            </LandingIntroReviewsAvatarWrapper>
                        </LandingIntroReviewsAvatarList>
                        <LandingIntroReviewsDescription>
                            <LandingIntroReviewsDescriptionRating>
                                <Star size={18} />
                                <Star size={18} />
                                <Star size={18} />
                                <Star size={18} />
                                <Star size={18} />
                            </LandingIntroReviewsDescriptionRating>
                            <LandingIntroReviewsDescriptionText>
                                {t('intro.ratingDescription', { amountThousandsUsers })}
                            </LandingIntroReviewsDescriptionText>
                        </LandingIntroReviewsDescription>
                    </LandingIntroReviews>

                    <LandingIntroNavigation>
                        <NavigationCourse href="/guide">
                            <NavigationItemInfo>
                                <NavigationItemTitle>
                                    {t('intro.guide.title')}
                                </NavigationItemTitle>
                                <NavigationItemAngle>
                                    <FontAwesomeIcon icon={faArrowRightLong} />
                                </NavigationItemAngle>
                                <NavigationItemDescription>
                                    {t('intro.guide.description')}
                                </NavigationItemDescription>
                            </NavigationItemInfo>
                        </NavigationCourse>

                        <NavigationTrainer href="/trainer">
                            <NavigationItemInfo>
                                <NavigationItemTitle>
                                    {t('intro.trainer.title')}
                                </NavigationItemTitle>
                                <NavigationItemAngle>
                                    <FontAwesomeIcon icon={faArrowRightLong} />
                                </NavigationItemAngle>
                                <NavigationItemDescription>
                                    {t('intro.trainer.description')}
                                </NavigationItemDescription>
                            </NavigationItemInfo>
                        </NavigationTrainer>

                        {isRu && (
                            <NavigationTelegram
                                href="https://t.me/sqlacademyofficial"
                                target="_blank"
                            >
                                <NavigationItemInfo>
                                    <NavigationItemTitle>
                                        {t('intro.telegram.title')}
                                    </NavigationItemTitle>
                                    <NavigationItemAngle>
                                        <FontAwesomeIcon icon={faArrowRightLong} />
                                    </NavigationItemAngle>
                                    <NavigationItemDescription>
                                        {t('intro.telegram.description')}
                                    </NavigationItemDescription>
                                </NavigationItemInfo>
                            </NavigationTelegram>
                        )}
                    </LandingIntroNavigation>

                    <LandingIntroBackgroundRadial />
                    <LandingIntroBackgroundTone />
                    <LandingIntroBackgroundBottomGradient />
                </LandingIntroContent>
            </LandingIntro>

            <LandingForWhom>
                <LandingForWhomWrapper>
                    <LandingForWhomContent
                        ref={LandingForWhomRef}
                        style={{
                            scale: scaleProgressLandingForWhom,
                            opacity: opacityProgressLandingForWhom,
                            rotateX: rotateXLandingForWhom,
                            rotateY: rotateYLandingForWhom,
                        }}
                    >
                        <LandingForWhomTitle>{t('forWhom.title')}</LandingForWhomTitle>
                        <LandingForWhomList>
                            <LandingForWhomListItem>
                                <LandingForWhomListItemIcon>
                                    <ChartArea size={32} />
                                </LandingForWhomListItemIcon>
                                <LandingForWhomListItemHeader>
                                    {t('forWhom.analytic.title')}
                                </LandingForWhomListItemHeader>
                                <LandingForWhomListItemDescription>
                                    {t('forWhom.analytic.description')}
                                </LandingForWhomListItemDescription>
                            </LandingForWhomListItem>
                            <LandingForWhomListItem>
                                <LandingForWhomListItemIcon>
                                    <FolderCode size={32} />
                                </LandingForWhomListItemIcon>
                                <LandingForWhomListItemHeader>
                                    {t('forWhom.programmer.title')}
                                </LandingForWhomListItemHeader>
                                <LandingForWhomListItemDescription>
                                    {t('forWhom.programmer.description')}
                                </LandingForWhomListItemDescription>
                            </LandingForWhomListItem>
                            <LandingForWhomListItem>
                                <LandingForWhomListItemIcon>
                                    <ShieldCheck size={32} />
                                </LandingForWhomListItemIcon>
                                <LandingForWhomListItemHeader>
                                    {t('forWhom.tester.title')}
                                </LandingForWhomListItemHeader>
                                <LandingForWhomListItemDescription>
                                    {t('forWhom.tester.description')}
                                </LandingForWhomListItemDescription>
                            </LandingForWhomListItem>
                            <LandingForWhomListItem>
                                <LandingForWhomListItemIcon>
                                    <GraduationCap size={32} />
                                </LandingForWhomListItemIcon>
                                <LandingForWhomListItemHeader>
                                    {t('forWhom.student.title')}
                                </LandingForWhomListItemHeader>
                                <LandingForWhomListItemDescription>
                                    {t('forWhom.student.description')}
                                </LandingForWhomListItemDescription>
                            </LandingForWhomListItem>
                        </LandingForWhomList>
                    </LandingForWhomContent>
                </LandingForWhomWrapper>
            </LandingForWhom>

            <LandingHowWeTeach>
                <LandingHowWeTeachContent>
                    {isRu ? <LandingHowWeTeachTextRu /> : <LandingHowWeTeachTextEn />}
                    <LandingHowWeTeachPlan
                        style={{
                            transform: translateXTransformHowWeTeachPlan,
                        }}
                    >
                        <LandingHowWeGuideContentWrapper>
                            <GuideContent modules={modules.slice(0, 3)} />
                        </LandingHowWeGuideContentWrapper>
                    </LandingHowWeTeachPlan>
                </LandingHowWeTeachContent>
            </LandingHowWeTeach>

            <LandingDemo>
                <LandingSectionTitle
                    css={`
                        max-width: unset !important;
                    `}
                >
                    {t('demo.part1')}
                    {'\n'}
                    {t('demo.part2')} <span>{t('demo.part3')}</span>
                </LandingSectionTitle>

                <LandingDemoContent
                    initial={{ opacity: 0.5 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{
                        margin: '0px 0px -30%',
                    }}
                >
                    <DemostrationOfSorting />
                </LandingDemoContent>
            </LandingDemo>

            <LandingTrainer>
                <LandingTrainerWrapper>
                    <LandingTrainerTitle>{t('trainer.title')}</LandingTrainerTitle>
                    <LandingTrainerContent href="/trainer" target="__blank">
                        <LandingTrainerVideo>
                            <video preload="none" autoPlay loop muted>
                                <source src="/static/trainerVideo.mp4" type="video/mp4" />
                            </video>
                            <LandingTrainerVideoContent>
                                <LandingTrainerVideoContentTitle>
                                    {t('trainer.toTrainer')}
                                </LandingTrainerVideoContentTitle>
                                <NavigationItemAngle>
                                    <FontAwesomeIcon icon={faArrowRightLong} />
                                </NavigationItemAngle>
                            </LandingTrainerVideoContent>
                        </LandingTrainerVideo>
                    </LandingTrainerContent>
                    <LandingTrainerBenefits>
                        <LandingTrainerBenefit
                            initial={{ opacity: 0.5 }}
                            whileInView={{ opacity: 1 }}
                            viewport={{
                                margin: '0px 0px -20%',
                            }}
                        >
                            <div>
                                <LayoutList size={40} strokeWidth={1.5} />
                            </div>
                            <div>{t('trainer.benefit1')}</div>
                        </LandingTrainerBenefit>
                        <LandingTrainerBenefit
                            initial={{ opacity: 0.5 }}
                            whileInView={{ opacity: 1 }}
                            viewport={{
                                margin: '0px 0px -20%',
                            }}
                        >
                            <div>
                                <FolderTree size={40} strokeWidth={1.5} />
                            </div>
                            <div>{t('trainer.benefit2')}</div>
                        </LandingTrainerBenefit>
                        <LandingTrainerBenefit
                            initial={{ opacity: 0.5 }}
                            whileInView={{ opacity: 1 }}
                            viewport={{
                                margin: '0px 0px -20%',
                            }}
                        >
                            <div>
                                <Save size={40} strokeWidth={1.5} />
                            </div>
                            <div>{t('trainer.benefit3')}</div>
                        </LandingTrainerBenefit>
                    </LandingTrainerBenefits>
                </LandingTrainerWrapper>
            </LandingTrainer>
            <LandingInterviewTasks>
                <LandingInterviewTasksTitle>
                    {t('interviewTasks.title')}
                </LandingInterviewTasksTitle>
                <LandingInterviewTasksContent>
                    <LandingInterviewTasksList>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={143}
                                height={50}
                                src="/static/companiesLogo/alfabank.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Alfa-Bank',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={50}
                                height={50}
                                src="/static/companiesLogo/mtc.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'MTC',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={150}
                                height={46}
                                src="/static/companiesLogo/dodo.jpeg"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Dodo Brands',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={50}
                                height={50}
                                src="/static/companiesLogo/vk.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'VK',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={150}
                                height={39}
                                src="/static/companiesLogo/microsoft.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Microsoft',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={150}
                                height={43.5}
                                src="/static/companiesLogo/otkritie.jpg"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Bank Otkritie',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={45}
                                src="/static/companiesLogo/twitter.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Twitter',
                                        lang,
                                    }),
                                })}
                                height={45}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                height={22.5}
                                width={150}
                                src="/static/companiesLogo/samokat.svg"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Samokat',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={150}
                                height={47}
                                src="/static/companiesLogo/sber.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Sberbank',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={120}
                                height={30}
                                src="/static/companiesLogo/uchi.svg"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Uchi.ru',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={150}
                                height={45}
                                src="/static/companiesLogo/spotify.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Spotify',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={150}
                                height={43.5}
                                src="/static/companiesLogo/tiktok.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'TikTok',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={143}
                                height={50}
                                src="/static/companiesLogo/alfabank.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Alfa-Bank',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={50}
                                height={50}
                                src="/static/companiesLogo/mtc.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'MTC',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={150}
                                height={46}
                                src="/static/companiesLogo/dodo.jpeg"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Dodo Brands',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={50}
                                height={50}
                                src="/static/companiesLogo/vk.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'VK',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={150}
                                height={39}
                                src="/static/companiesLogo/microsoft.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Microsoft',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={150}
                                height={43.5}
                                src="/static/companiesLogo/otkritie.jpg"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Bank Otkritie',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={45}
                                src="/static/companiesLogo/twitter.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Twitter',
                                        lang,
                                    }),
                                })}
                                height={45}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                height={22.5}
                                width={150}
                                src="/static/companiesLogo/samokat.svg"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Samokat',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={150}
                                height={47}
                                src="/static/companiesLogo/sber.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Sberbank',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={120}
                                height={30}
                                src="/static/companiesLogo/uchi.svg"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Uchi.ru',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={150}
                                height={45}
                                src="/static/companiesLogo/spotify.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'Spotify',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                        <LandingInterviewTaskCompany>
                            <Image
                                width={150}
                                height={43.5}
                                src="/static/companiesLogo/tiktok.png"
                                alt={t('interviewTasks.interviewTo', {
                                    company: getLocalCompanyName({
                                        company: 'TikTok',
                                        lang,
                                    }),
                                })}
                            />
                        </LandingInterviewTaskCompany>
                    </LandingInterviewTasksList>
                </LandingInterviewTasksContent>
            </LandingInterviewTasks>

            <LandingCertificate>
                <LandingCertificateTitle>
                    {t('certificate.title')}
                </LandingCertificateTitle>
                <LandingCertificateDescription>
                    {t('certificate.description')}
                </LandingCertificateDescription>
                <LandingCertificateContent>
                    <LandingCertificateImageBackdrop
                        ref={behineCertificateRef}
                        src="/static/certificate.webp"
                        style={{
                            transform: rotateTransformHehineCertificate1,
                        }}
                        alt="SQL certificate"
                        loading="lazy"
                    />

                    <LandingCertificateImageBackdrop
                        src="/static/certificate.webp"
                        style={{
                            transform: rotateTransformHehineCertificate2,
                        }}
                        alt="SQL certificate"
                    />

                    <img src="/static/certificate.webp" alt="SQL certificate" />
                </LandingCertificateContent>
            </LandingCertificate>
            <LandingReview>
                <LandingReviewTitle>
                    {t('review.title1part')} <Heart size={43} /> {t('review.title2part')}
                </LandingReviewTitle>
                <LandingReviewColumns>
                    <LandingReviewRow>
                        <LandingReviewItem
                            initial={{ opacity: 0.8, translateY: 10, scale: 0.9 }}
                            whileInView={{ opacity: 1, translateY: 0, scale: 1 }}
                            transition={{ duration: 0.4 }}
                        >
                            <LandingReviewItemHeader>
                                <LandingReviewItemHeaderAvatar>
                                    <Image
                                        width={30}
                                        height={30}
                                        src="/static/reviewsAvatars/review4.webp"
                                        alt="SQL course review avatar"
                                    />
                                </LandingReviewItemHeaderAvatar>
                                <LandingReviewItemHeaderName>
                                    {t('review.review4.name')}
                                </LandingReviewItemHeaderName>
                            </LandingReviewItemHeader>
                            <LandingReviewItemText>
                                {t('review.review4.text')}
                            </LandingReviewItemText>
                        </LandingReviewItem>
                    </LandingReviewRow>
                    <LandingReviewRow>
                        <LandingReviewItem
                            initial={{ opacity: 0.8, translateY: 10, scale: 0.9 }}
                            whileInView={{ opacity: 1, translateY: 0, scale: 1 }}
                            transition={{ duration: 0.4 }}
                        >
                            <LandingReviewItemHeader>
                                <LandingReviewItemHeaderAvatar>
                                    <Image
                                        width={30}
                                        height={30}
                                        src="/static/reviewsAvatars/review3.webp"
                                        alt="SQL course review avatar"
                                    />
                                </LandingReviewItemHeaderAvatar>
                                <LandingReviewItemHeaderName>
                                    {t('review.review3.name')}
                                </LandingReviewItemHeaderName>
                            </LandingReviewItemHeader>
                            <LandingReviewItemText>
                                {t('review.review3.text')}
                            </LandingReviewItemText>
                        </LandingReviewItem>
                        <LandingReviewItem
                            initial={{ opacity: 0.8, translateY: 10, scale: 0.9 }}
                            whileInView={{ opacity: 1, translateY: 0, scale: 1 }}
                            transition={{ duration: 0.4 }}
                        >
                            <LandingReviewItemHeader>
                                <LandingReviewItemHeaderAvatar>
                                    <Image
                                        width={30}
                                        height={30}
                                        src="/static/reviewsAvatars/review2.jpg"
                                        alt="SQL course review avatar"
                                    />
                                </LandingReviewItemHeaderAvatar>
                                <LandingReviewItemHeaderName>
                                    {t('review.review2.name')}
                                </LandingReviewItemHeaderName>
                            </LandingReviewItemHeader>
                            <LandingReviewItemText>
                                {t('review.review2.text')}
                            </LandingReviewItemText>
                        </LandingReviewItem>
                    </LandingReviewRow>
                    <LandingReviewRow>
                        <LandingReviewItem
                            initial={{ opacity: 0.8, translateY: 10, scale: 0.9 }}
                            whileInView={{ opacity: 1, translateY: 0, scale: 1 }}
                            transition={{ duration: 0.4 }}
                        >
                            <LandingReviewItemHeader>
                                <LandingReviewItemHeaderAvatar>
                                    <Image
                                        width={30}
                                        height={30}
                                        src="/static/reviewsAvatars/review5.webp"
                                        alt="SQL course review avatar"
                                    />
                                </LandingReviewItemHeaderAvatar>
                                <LandingReviewItemHeaderName>
                                    {t('review.review5.name')}
                                </LandingReviewItemHeaderName>
                            </LandingReviewItemHeader>
                            <LandingReviewItemText>
                                {t('review.review5.text')}
                            </LandingReviewItemText>
                        </LandingReviewItem>
                        <LandingReviewItem
                            initial={{ opacity: 0.8, translateY: 10, scale: 0.9 }}
                            whileInView={{ opacity: 1, translateY: 0, scale: 1 }}
                            transition={{ duration: 0.4 }}
                        >
                            <LandingReviewItemHeader>
                                <LandingReviewItemHeaderAvatar>
                                    <Image
                                        width={30}
                                        height={30}
                                        src="/static/reviewsAvatars/review6.jpg"
                                        alt="SQL course review avatar"
                                    />
                                </LandingReviewItemHeaderAvatar>
                                <LandingReviewItemHeaderName>
                                    {t('review.review6.name')}
                                </LandingReviewItemHeaderName>
                            </LandingReviewItemHeader>
                            <LandingReviewItemText>
                                {t('review.review6.text')}
                            </LandingReviewItemText>
                        </LandingReviewItem>
                        <LandingReviewItem
                            initial={{ opacity: 0.8, translateY: 10, scale: 0.9 }}
                            whileInView={{ opacity: 1, translateY: 0, scale: 1 }}
                            transition={{ duration: 0.4 }}
                        >
                            <LandingReviewItemHeader>
                                <LandingReviewItemHeaderAvatar>
                                    <Image
                                        width={30}
                                        height={30}
                                        src="/static/reviewsAvatars/review1.jpg"
                                        alt="SQL course review avatar"
                                    />
                                </LandingReviewItemHeaderAvatar>
                                <LandingReviewItemHeaderName>
                                    {t('review.review1.name')}
                                </LandingReviewItemHeaderName>
                            </LandingReviewItemHeader>
                            <LandingReviewItemText>
                                {t('review.review1.text')}
                            </LandingReviewItemText>
                        </LandingReviewItem>
                    </LandingReviewRow>
                </LandingReviewColumns>
            </LandingReview>

            <LandingFAQ>
                <LandingFAQTitle>FAQ</LandingFAQTitle>
                <LandingFAQContent>
                    <CollapsePanel title={t('faq.question1.title')} initialState={true}>
                        <ul>
                            <li>{t('faq.question1.benefit1')}</li>
                            <li>{t('faq.question1.benefit2')}</li>
                            <li>{t('faq.question1.benefit3')}</li>
                        </ul>
                    </CollapsePanel>
                    <CollapsePanel title={t('faq.question2.title')} initialState={true}>
                        {t('faq.question2.description')}
                    </CollapsePanel>
                    <CollapsePanel title={t('faq.question3.title')} initialState={true}>
                        {t('faq.question3.description')}
                    </CollapsePanel>
                    <CollapsePanel title={t('faq.question4.title')} initialState={true}>
                        {t('faq.question4.description')}
                        <a href="mailto:feedback@sql-academy.org" target="__blank">
                            feedback@sql-academy.org
                        </a>
                    </CollapsePanel>
                </LandingFAQContent>
            </LandingFAQ>
            <LandingIntroPrint />
        </LandingContentWrapper>
    );
};

export default LandingContent;
