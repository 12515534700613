import { IQuestionDifficulty } from '@interfaces/questions';

export const questionDifficultyToNumber = (difficulty: IQuestionDifficulty) => {
    if (difficulty === IQuestionDifficulty.HARD) return 2;
    if (difficulty === IQuestionDifficulty.MEDIUM) return 1;

    return 0;
};

const ruCompanyNameMap = {
    'Alfa-Bank': 'Альфа банк',
    DomClick: 'ДомКлик',
    Sberbank: 'Сбербанк',
    Samokat: 'Самокат',
    'Uchi.ru': 'Учи.ру',
    'Bank Otkritie': 'Банк Открытие',
    MTC: 'МТС',
};

const enCompanyNameMap = {
    MTC: 'MTS',
};

export const getLocalCompanyName = ({
    lang,
    company,
}: {
    lang: string;
    company: string;
}) => {
    if (lang === 'ru') {
        return ruCompanyNameMap[company] || company;
    }

    if (lang === 'en') {
        return enCompanyNameMap[company] || company;
    }

    return company;
};
